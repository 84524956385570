import React from 'react';

import SiteFooter from '../SiteFooter/SiteFooter';

const Footer = () => {

  return (
    <>
    
      <SiteFooter/>

    </>
  )
}

export default Footer