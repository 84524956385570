import React from 'react';
import PropTypes from 'prop-types';
import Card from '../Card/Card';

const CardGrid = ({ contentArr }) => {

  const setGridCols = () => {
    if (contentArr.length <= 2) {
      return 'grid-cols-1 sm:grid-cols-2 justify-center';
    } else {
      return 'grid-cols-1 sm:grid-cols-3';
    }
  }





  return (
    <div className={`grid gap-0 ${setGridCols()} `}>
      {contentArr.map((item, idx) => ( <a onClick={() => window.location.href = item.buttonHref}  style={{ cursor: 'pointer' }}>
        <div className="border " key={`cg-div-item${idx}`}>
          <Card
            titleText={item.titleText}
            bodyText={item.bodyText}
            imageSrc={item.imageSrc}
            altText={item.altText}
            buttonHref={item.buttonHref}
            buttonText={item.buttonText}
            type={item.type}
            author={item.author}
            agency={item.agency}
            date={item.date}
            icon={item.icon}
          />
        </div>
        </a>
      ))}
    </div>
  );
};

CardGrid.propTypes = {
  contentArr: PropTypes.array.isRequired
};

export default CardGrid;