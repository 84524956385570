import React from "react";

function AccountPage() {
    return (
        <>
            <div className=" bg-white">
                <h2 className="container mx-auto text-3xl font-bold text-gray-800 mb-8 mt-[30px] sm:mt-[170px]">Mi cuenta</h2>
                <div className="flex items-center justify-center  bg-[#F6F6F6] p-4">
                    <div className="w-full max-w-5xl">
                        <div className="bg-white shadow-md rounded-2xl p-6 h-96 justify-center my-10">
                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountPage;