import React, {useEffect} from 'react';
import bienestarBackground from '../../img/bienestarCiudadano.png';
import CardGrid from '../../patterns/CardGrid/CardGrid';
import useFetch from '../../hooks/useFetch';
import config from '../../config.json';

const BienestarCards = () => {

    const [contentBienestar, setContentBienestar] = React.useState([]);

    const { data, error, isLoading, fetchData } = useFetch(
        'GET',
        `${config.API_URL}/maps/procedures.php?subprocedure=46&procedure_type=BC&action=json`,
        null,
        { 'Content-Type': 'application/json' });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {

        if (data) {
            const responseMap = (data.procedures || []).map(procedure => ({
                type: 'bienestar',
                bodyText: procedure.subtitle,
                icon: procedure.icon,
                titleText: procedure.title.charAt(0).toUpperCase() + procedure.title.slice(1),
                buttonHref: `/bienestar-ciudadano?result_id=${procedure.id}`
            }));
            setContentBienestar(responseMap);
        }

    }, [data]);

    if (contentBienestar.length > 0) {
    return (
        
        <div className='pt-6 pb-36 ' style={{ backgroundImage: `url('https://tdf-img.s3.us-west-2.amazonaws.com/Backoffice/bienestarCiudadano.8588919fdb678b88c087.png')`, backgroundSize: 'cover' }}>
            <div className='container p-t-lg px-[0px]' style={{ height: 'auto', backgroundColor: '#FFFFFF' }} >

                <section className='w-full h-full  align-items-center' style={{ backgroundColor: '#FFFFFF' }}>
                    <div className='d-block justify-start pb-4'>
                        <h2 id='bienestar-ciudadano' className='text-4xl font-bold '>Servicios</h2>
                        <span> <svg width="28" height="7" viewBox="0 0 28 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="7" fill="#F75318" />
                        </svg>
                        </span>
                    </div>
                    <CardGrid
                        contentArr={contentBienestar}
                    />
                </section>
            </div>
        </div>
        
    )
    } else {
        return null;
    }
}

export default BienestarCards;