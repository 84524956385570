import React, { useState, useEffect } from 'react';
import SearchComponent from '../../structure/Search/SearchComponent';

const FullSpanBanner = () => {
  const main_carrusel = [
    'https://tdf-img.s3.us-west-2.amazonaws.com/Backoffice/planeta-banner.png'
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % main_carrusel.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [main_carrusel.length]);

  return (
    <div className="header-primary-banner">
      <div className="carousel-container" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {main_carrusel.map((image, index) => (
          <div
            key={index}
            className="carousel-slide"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </div>

      
      <SearchComponent />

      <div className="carousel-indicators">
        {main_carrusel.map((_, index) => (
          <div
            key={index}
            className={currentIndex === index ? 'active' : ''}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default FullSpanBanner;