import React from 'react';
import MobileControls from '../MobileControls/MobileControls';
import Logo from '../../structure/SiteHeader/logo.png';

const MobileHeader = () => {

    return (

        <header role={'banner'} id={'header'} className={'global-header fixed w-screen'} style={{ overflow: 'hidden' ,zIndex: '800', 'box-shadow': "0px 2px 4px rgba(9, 8, 66, 0.08), 0px 4px 24px rgba(9, 8, 66, 0.04)" }}>
            <div id="skip-to-content">
                <a href="#main-content">Skip to Main Content</a>
            </div>
            <div className="d-flex items-center justify-center w-screen" style={{ height: '76px', width: '100%'}}>
                <div className="d-flex w-screen" style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <div className=" w-screen " style={{ width: '100%'  }} >
                        <div className="d-flex items-center justify-between" style={{ height: '76px' }}>
                            <div className="logo-assets">
                                <a href="/" >
                                    <img src='https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/logoChubut.png' style={{ width: '150px' }} alt="Somos Tierra del Fuego" />
                                </a>
                            </div>
                            <MobileControls />
                        </div>
                    </div>
                </div>
            </div>
        </header >

    )
}
export default MobileHeader