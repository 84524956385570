import React from 'react';
import SiteHeader from '../SiteHeader/SiteHeader';
import MobileControls from '../MobileControls/MobileControls';


const Header = () => {


  return (
    <SiteHeader />
  )
}
  
  {/* <header role={'banner'} id={'header'} className={'global-header fixed'} style={{ overflow: 'hidden' }}>
    <div id="skip-to-content">
      <a href="#main-content">Skip to Main Content</a>
    </div>
    <SiteHeader />
  </header > */}


export default Header