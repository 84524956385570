import React from "react";

function CredentialCard() {
    return (
        <>
            <div className=" bg-white">
                <h2 className="container mx-auto text-3xl font-bold text-gray-800 mb-8 mt-[30px] sm:mt-[170px]">Mi credencial</h2>
                <div className="flex items-center justify-center  bg-[#F6F6F6] p-4">
                    <div className="w-full max-w-5xl">
                        <div className="bg-white shadow-md rounded-2xl p-6 flex justify-center my-10">
                            <div className="w-full sm:w-1/3 my-8 p-6">
                                {/* <div className="flex items-center  justify-center border-2 border-dashed border-gray-300 rounded-xl h-80 w-full mb-6">
                                    <div className="flex flex-col items-center justify-center">
                                        <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="44" cy="44" r="44" fill="#EFEFEF" />
                                            <path d="M59.4009 63.7997H61.6237C63.7603 63.7997 64.8285 63.7997 65.4174 63.3542C65.9304 62.9661 66.2479 62.373 66.2862 61.7308C66.3303 60.9937 65.7377 60.1049 64.5525 58.3272L57.9297 48.3929C56.9504 46.924 56.4608 46.1896 55.8436 45.9336C55.3041 45.7099 54.6977 45.7099 54.1581 45.9336C53.541 46.1896 53.0513 46.924 52.0721 48.3929L50.4349 50.8488M59.4009 63.7997L42.495 39.3801C41.5227 37.9757 41.0366 37.2735 40.4294 37.0267C39.8982 36.8107 39.3036 36.8107 38.7724 37.0267C38.1652 37.2735 37.679 37.9757 36.7068 39.3801L23.6249 58.2761C22.3863 60.0653 21.7669 60.9598 21.8022 61.7039C21.8329 62.3518 22.148 62.9532 22.6633 63.3472C23.2549 63.7997 24.3429 63.7997 26.519 63.7997H59.4009ZM63.8009 30.7997C63.8009 34.4448 60.846 37.3997 57.2009 37.3997C53.5558 37.3997 50.6009 34.4448 50.6009 30.7997C50.6009 27.1546 53.5558 24.1997 57.2009 24.1997C60.846 24.1997 63.8009 27.1546 63.8009 30.7997Z" stroke="#A2A2A2" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <p className="text-gray-500 mt-2">Credencial</p>
                                    </div>
                                </div> */}
                                <div className="flex items-center  justify-center   rounded-xl h-96 w-full mb-6">
                                    <img src="https://id.gobiernodechubut.com/pkpass/api/chubut/a02a0d20-c8c0-441d-84b2-f9ddea3b8e69/image" className="object-fit w-full h-full rounded-xl" alt="" />
                                </div>
                                
                                <button 
                                onClick={async () => {
                                    try {
                                      const response = await fetch("https://id.gobiernodechubut.com/pkpass/api/chubut/a02a0d20-c8c0-441d-84b2-f9ddea3b8e69/image");
                                      const blob = await response.blob();
                                      const url = URL.createObjectURL(blob);
                                      
                                      const link = document.createElement("a");
                                      link.href = url;
                                      link.download = "credencial-digital.png";
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                
                                      
                                      URL.revokeObjectURL(url);
                                    } catch (error) {
                                      console.error("Error al descargar la imagen:", error);
                                    }
                                  }}
                                
                                className="w-full  bg-orange-500 text-white h-12 rounded-full font-medium text-lg flex items-center justify-center transition duration-300 hover:bg-orange-600">
                                    <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Descargar credencial
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CredentialCard;