import bannerImage from './patterns/Banner/main-banner.svg';
import BackgroundOrange from './img/background-card.png';
import Banner from './patterns/Banner/Banner';
import CardGrid from './patterns/CardGrid/CardGrid';
import noticiasPlaceholder from './img/noticias.png';
import bienestarBackground from './img/bienestarCiudadano.png';
import Widgets from './structure/Widgets/Widgets';
import Carousel from './patterns/Banner/Carousel';
import News from './structure/News/News';
import BienestarCards from './components/BienestarCiudadano/bienestarCards';
import { useEffect } from 'react';
import HeroSection from './components/Home/HeroSection';
import Services from './structure/Services/Services';

function Home() {

  return (
    <>
      <div className={'main-content'} id={'main-content'}>
        <main id={'main'} className={'main-primary'}>

          <HeroSection />


          <div className='pt-36' id="servicios">

            <Services />

          </div>
          <div className='px-4 sm:px-8 caja-banner radius-18' style={{ background: '#FFF' }}>
            <Carousel />
          </div>

          <div style={{ background: '#EFEFEF'}} class="caja-novedades">
            
            <div className='container-fluid p-t-lg menu-ancho-100' style={{ padding: '0px' }}>
              
              <section className='w-full h-full  align-items-center'>
                <div className='d-flex justify-between items-center w-full h-full '>
                  <div className='d-block justify-start pb-4'>
                    <h2 className='text-4xl font-bold textos-separadores-cajas'  style={{ color: '#3b3a48' }}>Novedades</h2>

                  </div>

                  <div>
                    <button id='more-news' type='button' onClick={() => window.open('https://prensachubut.com/', '_blank')} className='flex items-center justify-center text-white rounded-full' style={{ backgroundColor: '#FF682C', width: '184px', height: '55px' }}>Ver más

                      
                      <svg style={{ marginLeft: '26px' }}  width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.9987 25.6654C20.442 25.6654 25.6654 20.442 25.6654 13.9987C25.6654 7.55537 20.442 2.33203 13.9987 2.33203C7.55537 2.33203 2.33203 7.55537 2.33203 13.9987C2.33203 20.442 7.55537 25.6654 13.9987 25.6654Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M9.33203 13.9993H18.6654M18.6654 13.9993L14.582 9.91602M18.6654 13.9993L14.582 18.0827" stroke="#FF682C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </button>

                  </div>
                </div>
              </section>
              <News />
            </div>
          </div>
          <a href="https://chubut-img.s3.eu-west-2.amazonaws.com/mapa/mapa_interactivo_chubut/index.html#">
          <div className='px-4 sm:px-8 caja-banner margin-bottom-100 objeto_solo_web'>
            <div class="flex items-center justify-center h-fit">
              <div class="relative w-full md:w-4/4 bg-white rounded-lg max-with">
                <img className='w-full objeto_solo_web radius-20' src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/banner-mapa.png" alt="bienestarBackground" />

              </div>
            </div>
          </div>
          <div className='caja-banner objeto_solo_mobil'>
            <div class="flex items-center justify-center h-fit">
              <div class="relative w-full md:w-4/4 bg-white rounded-lg max-with">
                <img className='w-full  radius-20 sin-radius-mobil' src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/imagen_bottom.png" alt="bienestarBackground" />

              </div>
            </div>
          </div>
          </a>
        </main>
      </div>

    </>
  );
}

export default Home;
