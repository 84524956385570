import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const searchData = {

    procedures: [

        { id: 28, procedure_type: 'GT', subprocedure: null, title: 'DNI', subtitle: 'Ejemplar Recien Nacido' },
        { id: 29, procedure_type: 'GT', subprocedure: 5, title: 'DNI', subtitle: 'ACTUALIZACIÓN DNI MENOR (5/8 años)' },
        { id: 30, procedure_type: 'GT', subprocedure: null, title: 'DNI', subtitle: 'ACTUALIZACIÓN DNI MAYOR (14 años)' },
        { id: 31, procedure_type: 'GT', subprocedure: 8, title: 'DNI', subtitle: 'NUEVO EJEMPLAR DNI' },
        { id: 32, procedure_type: 'GT', subprocedure: 8, title: 'DNI', subtitle: 'REPOSICIÓN DE DNI POR ERROR U OMISIÓN' },
        { id: 33, procedure_type: 'GT', subprocedure: 8, title: 'DNI', subtitle: 'DNI EXPRÉS' },
        { id: 34, procedure_type: 'GT', subprocedure: 8, title: 'DNI', subtitle: 'RECTIFICACIÓN DE DNI' },
        { id: 35, procedure_type: 'GT', subprocedure: 8, title: 'DNI', subtitle: 'CARTA DE CIUDADANÍA' },
        { id: 36, procedure_type: 'GT', subprocedure: 8, title: 'DNI', subtitle: 'CIUDADANÍA POR OPCIÓN' },
        { id: 18, procedure_type: 'GT', subprocedure: 8, title: 'Partidas', subtitle: 'ACTAS DE NACIMIENTO / MATRIMONIO / DEFUNCIÓN / UNIÓN CONVIVENCIAL' },
        { id: 47, procedure_type: 'GT', subprocedure: 8, title: 'Partidas', subtitle: 'PARTIDAS PARA RETIRAR DE LAS DELEGACIONES EN FORMATO PAPEL CON FIRMA OLOGRAFA' },
        { id: 48, procedure_type: 'GT', subprocedure: 8, title: 'Partidas', subtitle: 'PARTIDAS PARA CIUDADANOS QUE RESIDEN FUERA DE LA PROVINCIA' },
        { id: 39, procedure_type: 'GT', subprocedure: 8, title: 'Pasaporte', subtitle: 'PASAPORTE' },
        { id: 38, procedure_type: 'GT', subprocedure: 8, title: 'Pasaporte', subtitle: 'PASAPORTE EXPRÉS' },
        { id: 49, procedure_type: 'VI', subprocedure: 8, title: 'Vivienda', subtitle: 'Inscripción al Instituto Provincial de Vivienda y Hábitat' },
        { id: 1, procedure_type: 'GOB', subprocedure: 8, title: 'Gustavo Melella', subtitle: 'Gobernador' },
        { id: 2, procedure_type: "TIT", subprocedure: 8, title: "Mónica Urquiza", subtitle: "Vicegobernadora" },
        { id: 3, procedure_type: "TIT", subprocedure: 8, title: "Agustín Tita", subtitle: "Jefatura de Gabinete" },
        { id: 4, procedure_type: "TIT", subprocedure: 8, title: "Jorge Canals", subtitle: "Vice Jefatura de Gabinete" },
        { id: 5, procedure_type: "TIT", subprocedure: 8, title: "José Capdevilla", subtitle: "Secretaría General, Legal y Técnica" },
        { id: 6, procedure_type: "TIT", subprocedure: 8, title: "María Eugenia Peri", subtitle: "Secretaría de Prensa y Comunicación" },
        { id: 7, procedure_type: "TIT", subprocedure: 8, title: "Fabricio Gómez", subtitle: "Secretaría de Medios Digitales" },
        { id: 8, procedure_type: "TIT", subprocedure: 8, title: "Andrés Dachary", subtitle: "Secretaría de Malvinas, A.I.A.S. y Asuntos Internacionales" },
        { id: 9, procedure_type: "TIT", subprocedure: 8, title: "Federico León Giménez", subtitle: "Secretaría de Representación Política del Gobierno" },
        { id: 10, procedure_type: "TIT", subprocedure: 8, title: "José Manuel Díaz", subtitle: "Secretaría de Enlace con las Fuerzas de Seguridad" },
        { id: 11, procedure_type: "TIT", subprocedure: 8, title: "Alejandro Aguirre", subtitle: "Ministerio de Energía" },
        { id: 12, procedure_type: "TIT", subprocedure: 8, title: "Francisco Devita", subtitle: "Ministerio de Economía" },
        { id: 13, procedure_type: "TIT", subprocedure: 8, title: "Cecilia Rojo", subtitle: "Viceministra de Economía" },
        { id: 14, procedure_type: "TIT", subprocedure: 8, title: "Gabriela Castillo", subtitle: "Ministerio de Obras y Servicios Públicos" },
        { id: 15, procedure_type: "TIT", subprocedure: 8, title: "Karina Fernández", subtitle: "Ministerio de Producción y Ambiente" },
        { id: 16, procedure_type: "TIT", subprocedure: 8, title: "Judit Di Giglio", subtitle: "Ministerio de Salud" },
        { id: 17, procedure_type: "TIT", subprocedure: 8, title: "Adriana Chapperon", subtitle: "Ministerio de Bienestar Ciudadano y Justicia" },
        { id: 18, procedure_type: "TIT", subprocedure: 8, title: "Mónica Acosta", subtitle: "Viceministra de Bienestar Ciudadano y Justicia" },
        { id: 19, procedure_type: "TIT", subprocedure: 8, title: "Pablo López Silva", subtitle: "Ministerio de Educación" },
        { id: 20, procedure_type: "TIT", subprocedure: 8, title: "Sonia Castiglione", subtitle: "Ministerio de Trabajo y Empleo" }
        
    ]
};

const Modal = React.memo(({ showModal, setShowModal, modalSearchTerm, setModalSearchTerm, handleDropdownClick }) => {
    const modalInputRef = useRef(null);
    const [filteredModalResults, setFilteredModalResults] = useState([]);
    const [showRecentSearches, setShowRecentSearches] = useState(true);
    const [noResults, setNoResults] = useState(false);

    // Handler for changes in the search input
    const handleModalSearchChange = useCallback((e) => {
        const value = e.target.value;
        setModalSearchTerm(value);

        if (value.length === 0) {
            setFilteredModalResults([]);
            setNoResults(false);
            setShowRecentSearches(true);
        } else {
            const results = searchData.procedures.filter(item =>
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.subtitle.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredModalResults(results);
            setNoResults(results.length === 0);
            setShowRecentSearches(false);
        }
    }, [setModalSearchTerm]);

    // Effect to handle focus and results when modal opens
    useEffect(() => {
        if (showModal) {
            if (modalSearchTerm === "") {
                setFilteredModalResults([]);
                setNoResults(false);
                setShowRecentSearches(true);
            } else {
                const results = searchData.procedures.filter(item =>
                    item.title.toLowerCase().includes(modalSearchTerm.toLowerCase()) ||
                    item.subtitle.toLowerCase().includes(modalSearchTerm.toLowerCase())
                );
                setFilteredModalResults(results);
                setNoResults(results.length === 0);
                setShowRecentSearches(false);
            }
            if (modalInputRef.current) {
                modalInputRef.current.focus();
            }
        }
    }, [showModal, modalSearchTerm]);

    // Effect to reset filtered results when modalSearchTerm changes
    useEffect(() => {
        if (modalSearchTerm === "") {
            setFilteredModalResults([]);
            setNoResults(false);
            setShowRecentSearches(true);
        }
    }, [modalSearchTerm]);

    const modalStyles = {
        width: '1000px',
        height: '800px',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto'
    };

    return ReactDOM.createPortal(
        showModal && (
            <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="modal-content bg-white p-10" style={modalStyles}>
                    <button onClick={() => setShowModal(false)} className="absolute mt-3 mr-3 top-4 right-4 text-black">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.63354 31.3687L18.0023 18.0001M18.0023 18.0001L31.3709 4.63135M18.0023 18.0001L4.63354 4.63135M18.0023 18.0001L31.3709 31.3687" stroke="#383838" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <h2 className="font-bold pt-14" style={{ fontSize: window.innerWidth <= 768 ? '1.5rem' : '2rem', marginTop: 0, overflow: 'visible', paddingBottom: window.innerWidth <= 768 ? '1rem' : '1.5rem', color: '#0AB5FA' }}>
                        ¿Qué estás buscando?
                    </h2>

                    <div className="relative mb-6">
                        <i className="fas fa-search absolute top-3 left-3 text-gray-400"></i>
                        <input
                            type="text"
                            value={modalSearchTerm}
                            ref={modalInputRef}
                            onChange={handleModalSearchChange}
                            placeholder="Ejemplo: Turno para tramitar partida..."
                            className="w-full pl-10 pr-4 py-2 border-b-2 outline-none"
                            style={{ borderColor: '#0AB5FA' }}
                        />
                    </div>
                    <ul>
                        {showRecentSearches ? (
                            <div className="px-4 py-2 text-gray-500 text-sm">Escribe algo...</div>
                        ) : noResults ? (
                            <div className="px-4 py-2 text-gray-500 text-sm">No hay resultados</div>
                        ) : (
                            filteredModalResults.map((item) => (
                                <li key={item.id} className="p-2 cursor-pointer text-sm hover:bg-gray-100" onClick={() => handleDropdownClick(item.procedure_type, item.id)}>
                                    {item.title} - {item.subtitle}
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
        ),
        document.body
    );
});


const SearchComponent = () => {
    const router = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [recentSearches] = useState([
        "Calendario de Vacunación",
        "Tramitar partidas",
        "Mesa Fueguina"
    ]);

    const [modalSearchTerm, setModalSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalFilteredData, setModalFilteredData] = useState([]);

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    const handleSearchChange = useCallback((e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setModalSearchTerm(value);
        setShowDropdown(true);

        if (value === "") {
            setFilteredData(searchData.procedures);
        } else {
            const results = searchData.procedures.filter(item =>
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.subtitle.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredData(results);
        }
    }, []);

    const handleDropdownClick = useCallback((type, id) => {
        switch (type) {
            case "GT":
                router(`/guia-de-tramites?result_id=${id}`);
                break;
            case "VI":
                router(`/bienestar-ciudadano?result_id=${id}`);
                break;
            case "GOB":
                router('/institucional/gobernador');
                break;
            case "TIT":
                router('/institucional');
                break;
            default:
                router('/');
                break;
        }
    }, [router]);

    const handleSearchClick = useCallback(() => {
        setShowModal(true);
        setShowDropdown(false); // Oculta el dropdown cuando se hace clic en el botón de búsqueda
        if (modalSearchTerm === "") {
            setModalFilteredData(searchData.procedures);
        }
    }, [modalSearchTerm]);

    const handleOutsideClick = useCallback((e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target) && !inputRef.current.contains(e.target)) {
            setShowDropdown(false);
        }
    }, []);

    const handleSearchMobileClick = useCallback(() => {
        setShowModal(true);
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const Dropdown = () => {
        const inputRect = inputRef.current.getBoundingClientRect();
        const dropdownStyles = {
            marginTop: '10px',
            position: 'absolute',
            top: `${inputRect.bottom + window.scrollY}px`,
            left: `${inputRect.left + window.scrollX}px`,
            width: '650px',
            maxHeight:'260px !important',
            backgroundColor: 'white',
            border: '1px solid #fff',
            zIndex: 1000,
            maxHeight: '300px',
            overflowY: 'auto',
            borderRadius: '4px'
        };


        return ReactDOM.createPortal(
            <div
                id="dropdown-buscador"
                ref={dropdownRef}
                style={dropdownStyles}
            >
                {searchTerm === "" ? (
                    <div className="px-4 py-2 text-gray-500 text-sm">Escribe algo...</div>
                ) : filteredData.length > 0 ? (
                    filteredData.map((item) => (
                        <div key={item.id} className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100" onClick={() => handleDropdownClick(item.procedure_type, item.id)}>
                            {item.title} - {item.subtitle}
                        </div>
                    ))
                ) : (
                    <div className="px-4 py-2 text-gray-500 text-sm">No hay resultados</div>
                )}
            </div>,
            document.body
        );
    };

    /* const Modal = () => {
        const modalStyles = {
            width: '1000px',
            height: '800px',
            maxWidth: '100%',
            maxHeight: '100%'
        };

        return ReactDOM.createPortal(

            <div className="modal fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 ">
                <div className="modal-content bg-white p-10" style={modalStyles}>
                    <button onClick={handleCloseModal} className="absolute mt-3 mr-3 top-4 right-4 text-black">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.63354 31.3687L18.0023 18.0001M18.0023 18.0001L31.3709 4.63135M18.0023 18.0001L4.63354 4.63135M18.0023 18.0001L31.3709 31.3687" stroke="#383838" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
                        </svg>

                    </button>
                    <h2 className="font-bold pt-14" style={{ fontSize: window.innerWidth <= 768 ? '1.5rem' : '2rem', marginTop: 0, overflow: 'visible', paddingBottom: window.innerWidth <= 768 ? '1rem' : '1.5rem', color: '#0AB5FA' }}>
                        ¿Qué estás buscando?
                    </h2>

                    <div class="relative mb-6">
                        <i class="fas fa-search absolute top-3 left-3 text-gray-400"></i>
                        <input
                            type="text"
                            value={searchTerm}
                            ref={modalInputRef}
                            onChange={handleSearchChange}
                            placeholder="Ejemplo: Turno para tramitar partida..."
                            class="w-full pl-10 pr-4 py-2 border-b-2 outline-none"
                            style={{ borderColor: '#0AB5FA' }}
                        />
                    </div>
                    <ul>
                        {searchTerm === "" ? (
                            <div >
                                <h3 class="text-gray-500 text-sm mb-2">BÚSQUEDAS RECIENTES</h3>
                                <ul class="space-y-2 mb-2">
                                    <li class="flex justify-between items-center">
                                        <span className='text-sm'>Calendario de vacunación</span>
                                        <span class=" text-gray-600  text-sm border px-2 py-1 rounded" style={{ backgroundColor: '#F6F8F9' }}>Salud</span>
                                    </li>
                                    <li class="flex justify-between items-center">
                                        <span className='text-sm'>Tramitar partidas</span>
                                        <span class=" text-gray-600 text-sm px-2 border py-1 rounded" style={{ backgroundColor: '#F6F8F9' }}>Registro Civil</span>
                                    </li>
                                    <li class="flex justify-between items-center">
                                        <span className='text-sm'>Mesa Fueguina</span>
                                        <span class=" text-gray-600  text-sm px-2 border py-1 rounded" style={{ backgroundColor: '#F6F8F9' }}>Bienestar</span>
                                    </li>
                                </ul>
                            </div>
                        ) : filteredData.length > 0 ? (
                            filteredData.map((item) => (
                                <div key={item.id} className="my-2 py-2 pl-2 cursor-pointer text-lg hover:bg-gray-100" onClick={() => handleDropdownClick(item.procedure_type)}>
                                    {item.title} - {item.subtitle}
                                </div>
                            ))
                        ) : (
                            <li className="mb-2 text-gray-500 text-sm">No hay resultados</li>
                        )}
                    </ul>
                </div>
            </div>,
            document.body

        );
    };
 */
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" />
            </Helmet>
            <div className="container-center mx-auto p-4">
                <h2 className="text-white font-bold" 
                style={{  'font-style': 'normal', 'font-weight': '600', 'font-size': '44px', color: '#FFFFFF',fontSize: window.innerWidth <= 768 ? '2rem' : '44px', marginTop: 0, overflow: 'visible', paddingBottom: window.innerWidth <= 768 ? '1rem' : '1.5rem' }}>
                    ¿Qué estás buscando?
                </h2>
                <div className="input-container relative" style={{ width: '650px' }}>
                    <input
                        type="text"
                        ref={inputRef}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onClick={ () => setShowDropdown(true) }
                        placeholder="Ejemplo: Turnos para atención presencial..."
                        className="desktop-input text-black text-lg"
                        style={{ 'font-family': 'Public Sans', 'font-style': 'normal', 'font-weight': '400', 'font-size': '16px', color: '#939393' , padding: '14px', width: '400px'}}
                 
                    />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onClick={handleSearchMobileClick}
                        placeholder="Ejemplo: Turno para tramitar partida"
                        className="mobile-input text-black text-lg"
                    />
                    <button
                        type="button"
                        onClick={handleSearchClick}
                        onChange={handleSearchChange}
                        className="desktop-button text-white"
                        style={{ backgroundColor: '#0AB5FA', width: '180px', height: '56px' }}
                    >
                        <svg className='mr-2' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.813 14.8711L11.8369 10.8951C12.9205 9.56987 13.4532 7.87893 13.325 6.17198C13.1967 4.46502 12.4173 2.87267 11.1479 1.72427C9.87853 0.575873 8.21632 -0.0406994 6.50509 0.00208668C4.79386 0.0448727 3.16454 0.743743 1.95414 1.95414C0.743743 3.16454 0.0448727 4.79386 0.00208668 6.50509C-0.0406994 8.21632 0.575873 9.87853 1.72427 11.1479C2.87267 12.4173 4.46502 13.1967 6.17198 13.325C7.87893 13.4532 9.56987 12.9205 10.8951 11.8369L14.8711 15.813C14.9968 15.9344 15.165 16.0015 15.3397 16C15.5143 15.9985 15.6814 15.9284 15.8049 15.8049C15.9284 15.6814 15.9985 15.5143 16 15.3397C16.0015 15.165 15.9344 14.9968 15.813 14.8711ZM6.68251 12.0115C5.62855 12.0115 4.59825 11.6989 3.72191 11.1134C2.84556 10.5278 2.16254 9.69556 1.7592 8.72182C1.35587 7.74808 1.25034 6.6766 1.45595 5.64289C1.66157 4.60917 2.16911 3.65964 2.91437 2.91437C3.65964 2.16911 4.60917 1.66157 5.64289 1.45595C6.6766 1.25034 7.74808 1.35587 8.72182 1.7592C9.69556 2.16254 10.5278 2.84556 11.1134 3.72191C11.6989 4.59825 12.0115 5.62855 12.0115 6.68251C12.0099 8.09535 11.4479 9.44987 10.4489 10.4489C9.44987 11.4479 8.09535 12.0099 6.68251 12.0115Z" fill="white" />
                        </svg>
                        Buscar
                    </button>
                    {/* Dropdown */}
                    {showDropdown && !showModal && <Dropdown />}
                </div>
                {showModal && <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                modalSearchTerm={modalSearchTerm}
                setModalSearchTerm={setModalSearchTerm}
                modalFilteredData={modalFilteredData}
                setModalFilteredData={setModalFilteredData}
                handleDropdownClick={handleDropdownClick}
            />}
            </div>
        </>
    );
};

export default SearchComponent;