import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

const desktopImages = [

  {
    src: "https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/Banners_web_chubut_conectar%20futuro_desktop.jpg",
    link: "#"
  },
  {
    src: "https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/Banners_web_chubut_juegos comunales_desktop.jpg",
    link: "#"
  }
];

const mobileImages = [
  {
    src: "https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/Banners_web_chubut_conectar futuro_mobile.jpg",
    link: "#"
  },
  {
    src: "https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/Banners_web_chubut_juegos comunales_mobile.jpg",
    link: "#"
  }
];

const Carousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const images = isMobile ? mobileImages : desktopImages;

  return (
    <div className="flex items-center justify-center h-fit">
      <div className="relative w-full md:w-4/4 bg-white rounded-lg ">
        <Swiper
          modules={[Navigation, Pagination, Autoplay, EffectFade]}
          onSlideChange={() => {
            const currentScrollY = window.scrollY;  // Captura la posición actual del scroll
            setTimeout(() => {
              window.scrollTo(0, currentScrollY);  // Después del cambio de slide, mantiene la posición
            }, 0);  // Usamos un `setTimeout` para esperar a que termine el cambio de slide
          }}
          navigation={{
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev',
          }}
          pagination={{ clickable: true }}
          autoplay={window.innerWidth > 768 ? { delay: 3000 } : false}
          loop={window.innerWidth > 768}
          effect="fade"
          observer={false}
          observeParents={false}
          className="mySwiper overflow-visible"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={image.src}
                  alt={`Imagen ${index + 1}`}
                  className="w-full h-auto rounded-lg radius=18 img-banner-size radius-20"
                />
              </a>
            </SwiperSlide>
          ))}

          {/* Botones de Navegación personalizados */}
          <div
            className="custom-swiper-button-next cursor-pointer"
            style={{
              backgroundColor: '#fff',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              position: 'absolute',
              top: '50%',
              right: '0px',
              transform: 'translateY(-50%)',
              zIndex: 10,
            }}
          >
            {/* SVG para la flecha de navegación hacia la derecha */}
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 7C0.447715 7 4.82823e-08 7.44772 0 8C-4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928933C11.9526 0.538409 11.3195 0.538409 10.9289 0.928933C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM1 9L18 9L18 7L1 7L1 9Z" fill="#1C2026" />
            </svg>

          </div>
          <div
            className="custom-swiper-button-prev cursor-pointer"
            style={{
              backgroundColor: '#fff',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 10,
            }}
          >
            {/* SVG para la flecha de navegación hacia la izquierda */}
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 7C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9L18 7ZM0.292893 8.70711C-0.0976314 8.31658 -0.0976315 7.68342 0.292892 7.29289L6.65685 0.928933C7.04738 0.538409 7.68054 0.538409 8.07107 0.928933C8.46159 1.31946 8.46159 1.95262 8.07107 2.34315L2.41421 8L8.07107 13.6569C8.46159 14.0474 8.46159 14.6805 8.07107 15.0711C7.68054 15.4616 7.04738 15.4616 6.65686 15.0711L0.292893 8.70711ZM18 9L1 9L1 7L18 7L18 9Z" fill="#1C2026" />
            </svg>

          </div>
        </Swiper>

        {/* Paginación (Dots) */}
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
};

export default Carousel;