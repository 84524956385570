import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Chatbot = () => {

  const router = useNavigate();

  const botInitializedRef = useRef(false);

  const [procedureData, setProcedureData] = useState({});
  const pathname = window.location.pathname;
  const isTramitesRoute = pathname.startsWith('/chatbot/tramites/');

  useEffect(() => {
    const pathname = window.location.pathname;
    const isTramitesRoute = pathname.startsWith('/chatbot/tramites/');
    const isTurnosRoute = pathname.startsWith('/chatbot/turnos/');

    const fetchMessage = async (id) => {
      const endpoint = `https://backoffice.fire-bots.com/maps/procedures.php?subprocedure=${id}&procedure_type=CT&action=json&source=Y2h1`;
      try {
        const response = await fetch(endpoint);
        const data = await response.json();
        setProcedureData(data);
        return data.header.message; // Extraer el mensaje desde el JSON recibido
      } catch (error) {
        console.error('Error fetching message:', error);
        return 'Error al obtener el mensaje';
      }
    };

    if ((isTramitesRoute || isTurnosRoute) && !botInitializedRef.current) {
      const id = pathname.split('/').pop(); // Obtener el {id} de la ruta

      if (id) {
        fetchMessage(id).then((message) => {
          console.log(message);
        });
      }
    }

    // Función para obtener el mensaje desde el endpoint


  }, [window.location.pathname]);

  return (

    <>
      <div style={{ backgroundColor: '#F6F6F6' }} class="header-chatbot">
        <div className='container flex align-items-center w-full breadcrumb'>
          <div class="box-navigator flex">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M5 12.9997H3.66667C2.19391 12.9997 1 11.8057 1 10.333V6.13805C1 5.20556 1.48707 4.34082 2.28453 3.85751L5.61786 1.83731C6.4674 1.32246 7.5326 1.32246 8.38213 1.83731L11.7155 3.85751C12.5129 4.34082 13 5.20556 13 6.13805V10.333C13 11.8057 11.8061 12.9997 10.3333 12.9997H9M5 12.9997V10.333C5 9.22839 5.8954 8.33299 7 8.33299C8.1046 8.33299 9 9.22839 9 10.333V12.9997M5 12.9997H9" stroke="#929292" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <span onClick={() => router('/')} className='text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi icono-home'>
              Home
            </span>
          </div>

          <svg class="mx-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 6L1 11" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="box-navigator flex">
            <span onClick={() => router('/')} className='text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi'>
              Chatbot
            </span>
          </div>
          <svg class="mx-2" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 6L1 11" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="box-navigator flex">
            <span onClick={() => router('/')} className='text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi'>
              {procedureData?.header?.title}
            </span>
          </div>


        </div>
        <style jsx>{`
                    .img-header {
                        float:right;
                        position:absolute;
                        right:0px;
                        margin-top:48px;
                    }
                    .h-chatbot
                    {
                    padding-top: 80px;
                    }
                    `}</style>
        <div className='pt-8 header-title-mobil' >
          <div class="img-header">
            <img src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/header_path.png"></img>
          </div>
          <div className="container align-items-center w-full "><br></br>
            <h1 className="titulo-interno-header">{procedureData?.header?.title}</h1>
            <p className="titulo-interno-subtitulo">{procedureData?.header?.subtitle}</p>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#F6F6F6' }}>
        <main class="container-fluid mx-auto pt-6 pb-28 h-chatbot flex justify-center nav " >
          {isTramitesRoute
            ? (
              <iframe
                className="chatbot container w-full z-50 rounded-lg mt-8 h-[88px] flex justify-between menu-ancho chatbot-postition"
                src={`https://chubut-img.s3.eu-west-2.amazonaws.com/chatbot/prueba.html?tramites=1&mensaje=${procedureData?.header?.message}`}
              ></iframe>
            )
            : (
              <iframe
                className="chatbot container w-full z-50 rounded-lg mt-8 h-[88px] flex justify-between menu-ancho chatbot-postition"
                src={`https://chubut-img.s3.eu-west-2.amazonaws.com/chatbot/prueba.html?turnos=1&mensaje=${procedureData?.header?.message}`}
              ></iframe>
            )
          }
        </main>
      </div>
      <style jsx>{`
        @media (min-width: 768px) {
            .gov-card {
                height: 400px;
                width: 70%;
            }
            .staff-gov-card {
                height: 168px;
                                padding: 24px;
                border-radius:18px;
                box-shadow: 0px 4px 24px 0px #0908420A;
                box-shadow: 0px 2px 4px 0px #09084214;
            }
            .breadcrumb {
            height: 57px;
                padding-top: 175px;
            }
        } 
    .chatbot
    {
        height: 450px;
        width: 100%;
        border-radius:18px;
        margin-top:20px;
        margin-bottom:100px;
        z-index:10
    }
    #Botmaker
    {
        width:500px !important;
    }
    #wc-button
    {
        display:none !important;
    }
     @media (max-width: 768px) {
            .gov-card {
                height: 342px;
                width: 100%;
            }
                .staff-gov-card {
                height: 136px;
                                padding: 24px;
                border-radius:18px;
                box-shadow: 0px 4px 24px 0px #0908420A;
                box-shadow: 0px 2px 4px 0px #09084214;
            }
                .breadcrumb {
            height: 57px;
                padding-top: 20px;
            }
        } 
        @media (min-width: 768px) {
            .vice-gov-card {
                height: 400px;
                width: 30%;
                padding: 16px;
            }
        }
      }
    };
      
    `}</style>


    </>
  );
};

export default Chatbot;
