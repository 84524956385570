import React, { useState, useEffect } from 'react';
import Header from './Header';
import MobileHeader from './MobileHeader';

const ResponsiveHeader = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 999);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 999);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? <MobileHeader /> : <Header />;
};

export default ResponsiveHeader;