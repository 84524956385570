import React from "react";
import { useAuth0 } from "@auth0/auth0-react";


const MenuProfile = () => {

    const { user, logout } = useAuth0();

    return (
        <div className="w-full h-full pt-1 bg-[#F6F6F6]">
            <div
                className="flex items-center space-x-2 px-1 pb-4"
                style={{ height: '50px' }}
            >
                <div className="flex items-center justify-center mx-2 w-[36px] h-[36px] bg-[#21708C] text-white font-semibold rounded-full">
                    {user.name[0].toUpperCase() + user.name[1].toUpperCase()}
                </div>
                <span style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'semibold' }} className="">{user.name}</span>

            </div>
            <div className="flex flex-col w-full rounded-2xl bg-white px-[15px] pt-[21px] pb-[21px]" >
                <span className=' py-3 w-full text-[#A2A2A2] ml-1' style={{ fontSize: '12px', fontFamily: 'Roboto' }}>MI CUENTA</span>

                <a href="/" className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl'><div className='w-full pl-3 '>Mis Turnos</div></a>
                <a href="/credencial" className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl'><div className='w-full pl-3'>Mi Credencial</div></a>
                <a href="/cuenta" className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl border-b'><div className='w-full pl-3'>Mi Cuenta</div></a>
                <span className=' py-3 w-full text-[#A2A2A2] ml-1' style={{ fontSize: '12px', fontFamily: 'Roboto' }}>MENU</span>

                <a href="/institucional" className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl'><div className='w-full pl-3 '> Institucional </div></a>
                <a href="/tramites" className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl'><div className='w-full pl-3'>Tramites</div></a>
                <a href="/servicios" className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl'><div className='w-full pl-3'>Servicios </div></a>

                <a onClick={() => window.open('https://prensachubut.com/', '_blank')} className='py-3 w-full hover:bg-[#EFEFEF] rounded-xl border-b' ><div className='w-full pl-3'>Noticias</div></a>

                <a onClick={logout} className='flex flex-row items-center mt-3 w-full hover:bg-[#EFEFEF] rounded-xl'>
                    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.0013 12H14.418M14.418 12L11.668 15M14.418 12L11.668 9" stroke="#555960" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.4154 6V5C14.4154 3.89543 13.5199 3 12.4154 3H3.58203C2.47746 3 1.58203 3.89543 1.58203 5V19C1.58203 20.1046 2.47746 21 3.58203 21H12.4154C13.5199 21 14.4154 20.1046 14.4154 19V18" stroke="#555960" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <div className='w-full pl-3'>Cerrar Sesion</div></a>
            </div>

        </div>
    )
}

export default MenuProfile
