import React, { useState } from "react";

const InteractiveMap = () => {
    
    
    return (
    
       
            <iframe
                src='https://backoffice.fire-bots.com/maps/'
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    display: 'block'
                }}
                
            />
       
    );
};

export default InteractiveMap;