import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const DropdownProfile = () => {

    const [isOpen, setIsOpen] = useState(false);

    const { user, isAuthenticated, logout } = useAuth0();

    if (!isAuthenticated) {
        return null;
    } else {
        console.log(user);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                className="flex items-center space-x-2 px-1 bg-white border rounded-full hover:shadow-md focus:outline-none"
                style={{ height: '50px' }} // Ajusta la altura del botón según lo necesario
            >
                <div className="flex items-center justify-center mx-2 w-8 h-8 bg-[#21708C] text-white font-semibold rounded-full">
                    {user.name[0].toUpperCase() + user.name[1].toUpperCase()}
                </div>
                <span style={{ fontSize: '16px', fontFamily: 'Roboto', fontWeight: 'semibold' }} className="">{user.name}</span>
                <svg
                    className={`w-4 h-4 ml-2 transform ${isOpen ? "rotate-180" : ""}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-[30px] w-[200px] bg-white rounded-md shadow-lg z-10">
                    <ul className="py-2">
                        <li className="space-y-2" >
                            <a
                                href="/"
                                className="block px-2 py-2 mx-2 rounded text-gray-700 hover:bg-gray-100 cursor-pointer"
                            >
                                Mis turnos
                            </a>
                            <a
                                href="/credencial"
                                className="block px-2 py-2 mx-2 rounded text-gray-700 hover:bg-gray-100 cursor-pointer"
                            >
                                Mi credencial
                            </a>
                            <a
                                href="/cuenta"
                                className="block px-2 py-2 mx-2 rounded text-gray-700 hover:bg-gray-100 cursor-pointer"
                            >
                                Mi cuenta
                            </a>
                            <a
                                onClick={() => logout({ returnTo: window.location.origin })}
                                className="flex text-center justify-center px-2 py-2 mx-2 rounded text-gray-700 hover:bg-gray-100 cursor-pointer"
                            >
                                <svg className="mr-2 mt-1" width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 12H15M15 12L12 15M15 12L12 9" stroke="#555960" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15 6V5C15 3.89543 14.1046 3 13 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H13C14.1046 21 15 20.1046 15 19V18" stroke="#555960" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                Cerrar sesión
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );


};

export default DropdownProfile;
