import React, { useEffect, useState } from "react";
import xml2js from 'xml2js';
import { useNavigate } from 'react-router-dom';

const rssTdf = 'https://prensachubut.com/feed/rss';

// Función para eliminar las etiquetas HTML del contenido
const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.innerText;
};

function cleanDescription(description) {
    return description
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ');
}

const truncateText = (text, maxLength = 120) => {
    if (text.length <= maxLength) {
        return text;
    }
    
    const truncated = text.slice(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    
    return lastSpaceIndex > 0 ? truncated.slice(0, lastSpaceIndex) + '...' : truncated + '...';
};

const fetchAndConvertRSS = async (rssUrl) => {
    try {
        const response = await fetch(rssUrl);
        const xml = await response.text();

        const parser = new xml2js.Parser();
        const result = await parser.parseStringPromise(xml);

        const channel = result.rss.channel[0];
        const items = channel.item.map(item => ({
            title: item.title[0],
            link: item.link[0],
            description: item.description[0],
            content: item['content:encoded'] ? stripHtmlTags(item['content:encoded'][0]) : '',
            imageUrl: item['media:content'] ? item['media:content'][0].$.url : 'https://placehold.co/600x400',
            category: item.category,
            date : item['pubDate'][0]
        }));

        return {
            title: channel.title[0],
            link: channel.link[0],
            description: channel.description[0],
            items: items
        };
    } catch (error) {
        console.error('Error fetching or parsing RSS feed:', error);
        throw error;
    }
};

const News = () => {
    const router = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };
    const [newsData, setNewsData] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchAndConvertRSS(rssTdf)
            .then(data => {
                setNewsData(data);
            })
            .catch(() => {
                setError(true);
            });
    }, []);

    if (error || !newsData || newsData.items.length < 6) {
        return (
            <div className="container-fluid mx-auto py-4 h-96">
                <div className="mt-6 text-center flex justify-center">
                    No se puderon cargar las noticias
                </div>
            </div>
        );
    }

    const mainItem = newsData.items[0];
    const otherItems = newsData.items.slice(1, 3);
    const bottomItems = newsData.items.slice(3, 6);

    return (
        <div className="container-fluid mx-auto py-4" style={{ padding: '0px' }}>
            <div className="flex flex-wrap lg:flex-nowrap gap-6">
                <div className="w-full lg:w-1/2 main-card rounded-xl overflow-hidden" >
                <a href={mainItem.link}  target="_blank"  className="mt-2 flex items-center text-white rounded-xl" style={{ textDecoration: 'none' }}>
                    <div className="relative h-full imagen-novedades-principal">
                        <img src={mainItem.imageUrl} onError={(e) => e.target.src = 'https://prensachubut.com/uploads/bandera.jpg'} alt={mainItem.title} className="w-full h-96 md:h-96 sm:h-96 object-cover rounded-xl radius-18 over-image-color-news" />
                        <div className="absolute bottom-0 left-0 p-4 text-white w-full h-auto">
                            <span className="text-white px-2 py-1 text-xs font-bold rounded-xl caja-novedades-secundaria-category-text color-texto-categoria-primaria" style={{ backgroundColor: '#FECCB8  !important', 'color':'#FF682C  !important' }}>{mainItem.category}</span>

                            <p className="mt-2 font-bold text-xl text-white rounded-xl noticia-titulo-principal">{cleanDescription(truncateText(mainItem.title))}</p>
                            <div className="flex justify-between" style={{ marginTop: '10px' }}>
                            <p className="mt-2 font-bold text-lg text-white rounded-xl roboto fecha-nota-principal-fecha"  style={{ fontSize: '18px' }}>{formatFechaEspanol(mainItem.date)}</p>
                            <button class=" w-10 h-10 bg-orange-500 text-white p-2 rounded-full posicion-boton"><svg class="ml-[3px]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.00774 13.7343C0.617213 14.1248 0.617213 14.758 1.00774 15.1485C1.39826 15.539 2.03143 15.539 2.42195 15.1485L1.00774 13.7343ZM15.7148 1.44141C15.7148 0.889121 15.2671 0.441406 14.7148 0.441406L5.71484 0.441406C5.16256 0.441406 4.71484 0.889122 4.71484 1.44141C4.71484 1.99369 5.16256 2.44141 5.71484 2.44141L13.7148 2.44141L13.7148 10.4414C13.7148 10.9937 14.1626 11.4414 14.7148 11.4414C15.2671 11.4414 15.7148 10.9937 15.7148 10.4414L15.7148 1.44141ZM2.42195 15.1485L15.422 2.14851L14.0077 0.734299L1.00774 13.7343L2.42195 15.1485Z" fill="white"></path></svg></button>                                
                            </div>

                        </div>
                    </div></a>
                </div>
                <div className="lg:w-1/2 flex flex-col space-y-4 radius-18"  >
                    {otherItems.map((item, index) => (
                        <a href={item.link} target="_blank" className="flex items-center text-black mt-2 rounded-xl">
                        <div key={index} className="flex-1 flex rounded-xl overflow-hidden caja-novedades-secundaria  radius-18"  style={{ backgroundColor: '#FFF',height: '180px' }}>

                            <img src={item.imageUrl} alt={item.title} onError={(e) => e.target.src = 'https://prensachubut.com/uploads/bandera.jpg'} className="h-[145px] w-[145px] object-cover rounded-l-lg imagen-novedades-secundaria   radius-14" />
                            <div className="p-2 flex-grow flex flex-col rounded-r-lg  caja-novedades-secundaria-category">
                                <div>
                                    <span className="bg-white px-2 py-1 text-xs font-bold rounded-xl caja-novedades-secundaria-category-text color-texto-categoria-secundarias" style={{ backgroundColor:'#FFF3DB !important',color: '#FFB109 !important' }}>{item.category}</span>
                                    <p className="mt-2 font-bold text-md rounded-xl noticia-titulo-secundaria" style={{ fontWeight: '800'}}>{cleanDescription(truncateText(item.title,70))}</p>
                                    <p className="mt-2 font-bold text-sm rounded-xl news-fecha-secundario roboto"  style={{ fontSize: '14px',color:"#A2A2A2" }}>{formatFechaEspanol(item.date)}</p>
                                </div>
 

                              
                            </div>
                        </div>
                        </a>
                    ))}
                </div>
            </div>
            <div id="b-news" className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6"  style={{marginTop:"24px !important"}} >
                {bottomItems.map((item, index) => (
                    <a href={item.link}  target="_blank" className="mt-2 flex items-center text-black rounded-xl  radius-18">
                    <div key={index} className="relative rounded-xl w-full overflow-hidden">
                        <img src={item.imageUrl} alt={item.title} onError={(e) => e.target.src = 'https://prensachubut.com//uploads/bandera.jpg'} className="w-full h-[224px] object-cover rounded-xl   radius-14" />
                        <div className="pt-4 pb-4"  style={{ paddingTop: '1.75rem !important'}}>
                            <span className="text-white px-2 py-1 text-xs font-bold rounded-xl caja-novedades-secundaria-category-text color-texto-categoria-secundarias " style={{ backgroundColor: '#D4E9F1  !important',color:'#21708C !important' }}>{item.category}</span>
                            <p className="mt-2 font-bold rounded-xl noticia-titulo-secundaria" style={{ fontWeight: '800'}}>{cleanDescription(truncateText(item.title))}</p>
                            <p className="mt-2 font-bold text-sm rounded-xl  news-fecha-secundario roboto" style={{ fontSize: '14px',color:"#A2A2A2" }}>{formatFechaEspanol(item.date)}</p>
                        </div>
                    </div> </a>
                ))}
            </div>

            <div className="mt-6 text-center lg:hidden flex justify-center">
                <button type='button' onClick={() => window.open('https://prensachubut.com/', '_blank')} className='flex items-center justify-center text-white rounded-full' style={{ backgroundColor: '#FF682C', width: '184px', height: '55px' }}>
                    Ver más
                    <svg className="ml-3" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9987 25.6654C20.442 25.6654 25.6654 20.442 25.6654 13.9987C25.6654 7.55537 20.442 2.33203 13.9987 2.33203C7.55537 2.33203 2.33203 7.55537 2.33203 13.9987C2.33203 20.442 7.55537 25.6654 13.9987 25.6654Z" fill="white" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.33203 13.9993H18.6654M18.6654 13.9993L14.582 9.91602M18.6654 13.9993L14.582 18.0827" stroke="#FF682C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </button>
            </div>
            <style jsx>{`
            @media (max-width: 768px) {
                #b-news {
                    display: none;
                }
                .news-fecha-secundario
                {
                    font-size: 12px !important;
                    font-weight: 500 !important;
                    line-height: 30px !important;
                    text-align: left !important;

                }
            }
        `}</style>
        </div>
    );
};



const formatFechaEspanol = (fechaRFC822) => {
    // Crear un objeto Date a partir de la cadena de fecha RFC 822
    const fecha = new Date(fechaRFC822);
  
    // Usar Intl.DateTimeFormat para formatear la fecha
    const opciones = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
  
    // Formatear la fecha a español (es-ES)
    return new Intl.DateTimeFormat('es-ES', opciones).format(fecha);
  };
  

export default News;