import React from 'react';
import ResponsiveHeader from './Header/Responsiveheader';
import Footer from './Footer/Footer';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  return (
    <>
      <ResponsiveHeader />
      <Outlet />
      <Footer />
    </>
  );
};

export default DefaultLayout;