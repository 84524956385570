import React, { useEffect } from 'react';

const AccessibilityWidget = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.userway.org/widget.js';
        script.async = true;
        script.setAttribute('data-account', '6EfHBRj2cu');
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default AccessibilityWidget;