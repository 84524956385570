import React from 'react';
import { Outlet } from 'react-router-dom';

const NoHeaderFooterLayout = () => {
  return (
   
      <Outlet />
    
  );
};

export default NoHeaderFooterLayout;