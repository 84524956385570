import React from 'react';

const NotFound = () => {
    return (
        <div className="not-found-container h-screen w-full bg-cover bg-center bg-no-repeat flex items-center justify-center md:justify-end">

            <div className="flex flex-col items-center justify-center md:items-start text-start md:text-right md:mr-28 md:absolute md:right-10 md:top-1/2 md:transform md:-translate-y-1/2 ">
                <h1 className="text-lg md:text-xl font-bold mb-4 text-white w-[263px] text-le">
                    La página que estás buscando no existe o se produjo otro error.
                </h1>
                <a href="/" className="mt-2 w-full flex justify-between rounded-full bg-white  px-6 py-2 font-semibold text-[#FF8540] ">
                    VOLVER A INICIO
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9987 25.6654C20.442 25.6654 25.6654 20.442 25.6654 13.9987C25.6654 7.55537 20.442 2.33203 13.9987 2.33203C7.55537 2.33203 2.33203 7.55537 2.33203 13.9987C2.33203 20.442 7.55537 25.6654 13.9987 25.6654Z" fill="#FF8540" stroke="#FF8540" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.33203 13.9993H18.6654M18.6654 13.9993L14.582 9.91602M18.6654 13.9993L14.582 18.0827" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </a>
            </div>

            <style jsx>{`
                .not-found-container {
                    background-image: url("https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/404_movil.jpeg");
                }
                @media (min-width: 768px) {
                    .not-found-container {
                        background-image: url("https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/404_web.jpeg");
                    }
                }
                @media (max-width: 767px) {
                    .not-found-container > div {
                        position: absolute;
                        bottom: 0%;
                    }
                }
            `}</style>
        </div>
    );
};

export default NotFound;
