import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import config from "../../../config.json";


const Contenido = () => {
  const [idUrl, setIdUrl] = useState(() => {
    const procedureFromParameter = new URLSearchParams(
      window.location.search
    ).get("result_id");
    return procedureFromParameter ? Number(procedureFromParameter) : 1;
  });

  const {
    data: breadcrumbData,
    error: breadcrumbError,
    fetchData: fetchBreadcrumbs,
  } = useFetch(
    "GET",
    `${config.API_URL}/maps/procedures_front.php?action=json&broad=1&id_broad=${idUrl}&source=Y2h1`,
    null,
    {}
  );

  const { data, isLoading, error, fetchData } = useFetch(
    "GET",
    `${config.API_URL}/maps/procedures.php?subprocedure=${idUrl}&procedure_type=CN&action=json&source=Y2h1`,
    null,
    {}
  );

  const [procedure, setProcedure] = useState({
    header: {
      title: "",
      subtitle: "",
      color: "",
    },
    procedures: [],
    info_additional: {
      title: "",
      html: "",
      subtitle: "",
      begin_procedure_location: "",
      procedure_cost: "",
      procedure_type: "",
      who_can_do_procedure: "",
      procedure_documentation: "",
      subsidiaries: [],
    },
  });

  const [procedureId, setProcedureId] = useState(idUrl);
  const router = useNavigate();

  useEffect(() => {
    const getProcedureContent = () => {
      const procedure_from_parameter = new URLSearchParams(
        window.location.search
      ).get("result_id");
      let param = procedure_from_parameter
        ? procedure_from_parameter.toString()
        : procedureId.toString();
      setIdUrl(param);
    };
    getProcedureContent();
  }, [procedureId]);

  useEffect(() => {
    fetchData();
    fetchBreadcrumbs();
  }, [idUrl]);

  useEffect(() => {
    if (data) {
      setProcedure(data);
    }
  }, [data]);

  useEffect(() => {
    if (breadcrumbError) {
      console.warn("Breadcrumb error: " + breadcrumbError);
    }
  }, [breadcrumbError]);

  const handleProcedureClick = (procedureId) => {
    window.location.href = `/destacado?result_id=${procedureId}`;
  };

  const renderBreadcrumbs = () => {
    const isMobile = window.innerWidth <= 768;

    if (!breadcrumbData || !breadcrumbData.procedures) {
      return null;
    }

    const reversedProcedures = breadcrumbData.procedures.slice().reverse();

    if (isMobile) {
      const secondLastBreadcrumb =
        reversedProcedures.length > 1
          ? reversedProcedures[1]
          : reversedProcedures[0];
      return (
        <div
          key={procedureId}
          style={{
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
          }}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              display: "inline-flex",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <path
              d="M1 1L6 6L1 11"
              stroke="#929292"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span
            style={{
              display: "inline-flex",
              alignItems: "center",
              color: "#929292",
            }}
          >
            ...
          </span>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              display: "inline-flex",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <path
              d="M1 1L6 6L1 11"
              stroke="#929292"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="box-navigator box-navigator-black flex">
            <span
              onClick={() =>
                (window.location.href = `/destacado?result_id=${secondLastBreadcrumb.procedure_id}`)
              }
              className="  roboto"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                textDecorationColor: "#1C2026",
                color: "#1C2026",
                fontSize: "16px",
              }}
            >
              {secondLastBreadcrumb.title}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}
      >
        <svg
          className="mx-2"
          width="7"
          height="12"
          viewBox="0 0 7 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L6 6L1 11"
            stroke="#929292"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {reversedProcedures.map((item, index) => (
          <span
            key={item.procedure_id}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <div class="box-navigator box-navigator-black flex">
              <span
                onClick={() =>
                  (window.location.href = `/destacado?result_id=${item.procedure_id}`)
                }
                className="font-semibold text-sm-0"
                style={{
                  color:
                    index === reversedProcedures.length - 1
                      ? "black"
                      : "#929292",
                  cursor: "pointer",
                  textDecoration:
                    index === reversedProcedures.length - 1 ? "none" : "none",
                }}
              >
                {item.title}
              </span>
            </div>
            {index < reversedProcedures.length - 1 && (
              <svg
                className="mx-2"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6 6L1 11"
                  stroke="#929292"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </span>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="container-fluid w-full flex h-[500px] justify-center items-center ">
        <style>
          {`
          .loader {
            width: 48px;
            height: 48px;
            border: 5px solid #FFF;
            border-bottom-color: #FF3D00;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
        </style>
        <div className="loader"></div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="container-fluid w-full flex h-[500px] justify-center items-center">
        <p>Error al cargar los datos.</p>
      </div>
    );
  }

  if (!procedure) {
    return <p>Error: No se encontró el procedimiento.</p>;
  }

  if (!procedure.info_additional) {
    return (
      <>

        <div
          className="header-interno-top"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div
            className="container flex align-items-center w-full header-interno"
            style={{ height: "57px" }}
          >
            <div class="box-navigator flex">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12.9997H3.66667C2.19391 12.9997 1 11.8057 1 10.333V6.13805C1 5.20556 1.48707 4.34082 2.28453 3.85751L5.61786 1.83731C6.4674 1.32246 7.5326 1.32246 8.38213 1.83731L11.7155 3.85751C12.5129 4.34082 13 5.20556 13 6.13805V10.333C13 11.8057 11.8061 12.9997 10.3333 12.9997H9M5 12.9997V10.333C5 9.22839 5.8954 8.33299 7 8.33299C8.1046 8.33299 9 9.22839 9 10.333V12.9997M5 12.9997H9"
                  stroke="#929292"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <span
                onClick={() => router("/")}
                className="text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi icono-home"
              >
                Home
              </span>
            </div>

            {renderBreadcrumbs()}
          </div>
          <style jsx>{`
            .img-header {
              float: right;
              position: absolute;
              right: 0px;
              margin-top: 48px;
            }
            .elimina-estilos 
            {
             all: unset;
            }
          `}</style>
          <div className="pt-8 header-title-mobil">
            <div class="img-header">
              <img src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/header_path.png"></img>
            </div>
            <div className="container align-items-center w-full ">
              <br></br>
              <h1 className="titulo-interno-header">
                {procedure.header.title}
              </h1>
              <p className="titulo-interno-subtitulo">
              {truncateText(procedure.header.subtitle,120)}

              </p>
            </div>
          </div>
        </div>
        <div
          className="separador-header-internas"
          style={{ backgroundColor: "#FFF" }}
        >
          <main className="container flex align-items-center w-full border-FEA footer-distancia">
            <div
              className={`grid gap-6 
    ${
      procedure.procedures.length <= 2
        ? "grid-cols-1 sm:grid-cols-2 justify-center"
        : "grid-cols-1 sm:grid-cols-3"
    }
  `}
            >
              {procedure.procedures.map((proc) => (
                <a
                  class="cursor-pointer"
                  onClick={() => handleProcedureClick(proc.id)}
                >
                  <div
                    key={proc.id}
                    class="bg-white shadow-md flex box-mobil boxes-border"
                    style={{
                      width: "20em",
                      height: "128px",
                      borderRadius: "18px !important",
                    }}
                  >
                    {proc.icon === "" && null}

                    {proc.icon != "" ? (
                      <div
                        class="flex items-center justify-center"
                        style={{
                          backgroundColor: "#EFEFEF",
                          width: "82px",
                          marginLeft: "5px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "18px",
                        }}
                      >
                        <img src={proc.icon} class="w-8"></img>
                      </div>
                    ) : (
                      <div
                        class="flex items-center justify-center"
                        style={{
                          backgroundColor: "#EFEFEF",
                          width: "0em",
                          marginLeft: "5px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          borderRadius: "18px",
                        }}
                      ></div>
                    )}

                    <div class="flex items-center ml-4">
                      <div class="flex-col">
                        {proc.icon != "" ? (
                          <h3 class="text-xl font-bold w10em font-box-mobil">
                            {proc.title}
                          </h3>
                        ) : (
                          <h3 class="text-xl font-bold w16em font-box-mobil">
                            {proc.title}
                          </h3>
                        )}
                      </div>
                      <div>
                        <svg
                          class="flecha-interna"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="17.5"
                            fill="white"
                          />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="35"
                            height="35"
                            rx="17.5"
                            stroke="#DADADA"
                          />
                          <path
                            d="M15 12.5L21 18L15 23.5"
                            stroke="#1C2026"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </main>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="header-interno-top"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div
            className="container flex align-items-center w-full header-interno"
            style={{ height: "57px" }}
          >
            <div class="box-navigator flex">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12.9997H3.66667C2.19391 12.9997 1 11.8057 1 10.333V6.13805C1 5.20556 1.48707 4.34082 2.28453 3.85751L5.61786 1.83731C6.4674 1.32246 7.5326 1.32246 8.38213 1.83731L11.7155 3.85751C12.5129 4.34082 13 5.20556 13 6.13805V10.333C13 11.8057 11.8061 12.9997 10.3333 12.9997H9M5 12.9997V10.333C5 9.22839 5.8954 8.33299 7 8.33299C8.1046 8.33299 9 9.22839 9 10.333V12.9997M5 12.9997H9"
                  stroke="#929292"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <span
                onClick={() => router("/")}
                className="text-sm-0 self-center cursor-pointer cargo-white breadcrumb-mobi icono-home"
              >
                Home
              </span>
            </div>

            {renderBreadcrumbs()}
          </div>
          <style jsx>{`
            .img-header {
              float: right;
              position: absolute;
              right: 0px;
              margin-top: 48px;
            }
          `}</style>
          <div className="pt-8 header-title-mobil">
            <div class="img-header">
              <img src="https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/header_path.png"></img>
            </div>
            <div className="container align-items-center w-full ">
              <br></br>
              <h1 className="titulo-interno-header">
              {truncateText(procedure.header.title,38)}
              </h1>
              <p className="titulo-interno-subtitulo">
              {truncateText(procedure.header.subtitle,120)}

              </p>
            </div>
          </div>
        </div>
        <div class="pt-8 pb-8 container">
          {procedure.info_additional.seccond_title && (
            <div class="h-12 mb-3 border-b">
              <h1 class="text-2xl font-semibold mb-4">
                {procedure.info_additional.seccond_title}
              </h1>
            </div>
          )}

          <div
            className="mb-6 text-lg estilo-caja-html"
            dangerouslySetInnerHTML={{ __html: procedure.info_additional.html }}
          ></div>

          <div className="bg-gray-75">
            {procedure.info_additional.begin_procedure_location && (
              <div className="flex flex-col border-b sm:flex-row  caja_tramite">
                <div className="flex items-center md:pr-1 sm:pr-4 mt-2 ">
                  <div>
                    <svg
                      class="ml-2 mr-2 icono-tramites-detalles-mobil"
                      width="21"
                      height="19"
                      viewBox="0 0 21 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1_6112)">
                        <path
                          d="M20.7191 5.5575L17.85 4.12V0.5C17.85 0.2225 17.6164 0 17.325 0H14.7C14.4086 0 14.175 0.2225 14.175 0.5V2.2775L10.7441 0.5575C10.5919 0.4825 10.4081 0.4825 10.2559 0.5575L0.280875 5.5575C0.107625 5.6425 0 5.815 0 6V9C0 9.175 0.097125 9.3375 0.254625 9.4275C0.412125 9.5175 0.606375 9.5225 0.769125 9.4425L2.1 8.775V18.5C2.1 18.7775 2.33362 19 2.625 19H18.375C18.6664 19 18.9 18.7775 18.9 18.5V8.775L20.2309 9.4425C20.307 9.48 20.391 9.5 20.475 9.5C20.5695 9.5 20.664 9.475 20.7454 9.4275C20.9029 9.3375 21 9.175 21 9V6C21 5.815 20.8924 5.6425 20.7191 5.5575ZM8.4 18V12.5H12.6V18H8.4ZM17.85 18H13.65V12C13.65 11.7225 13.4164 11.5 13.125 11.5H7.875C7.58362 11.5 7.35 11.7225 7.35 12V18H3.15V8.25L10.5 4.565L17.85 8.25V18ZM19.95 8.1725L10.7441 3.5575C10.668 3.52 10.584 3.5 10.5 3.5C10.416 3.5 10.332 3.52 10.2559 3.5575L1.05 8.1725V6.3025L10.5 1.565L14.4559 3.5475C14.6186 3.6275 14.8155 3.6225 14.9704 3.5325C15.1279 3.4425 15.225 3.28 15.225 3.105V1H16.8V4.42C16.8 4.605 16.9076 4.7775 17.0809 4.8625L19.95 6.3V8.17V8.1725Z"
                          fill="#383838"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_6112">
                          <rect width="21" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <p className="font-semibold text-lg sm:text-xl">
                      Dependencias donde se inicia el trámite:{" "}
                      {procedure.info_additional.begin_procedure_location}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {procedure.info_additional.procedure_cost && (
              <div
                className="flex flex-col sm:flex-row items-start mt-2 mb-4 caja_tramite"
                style={{ "border-top": "0.6px #939393 solid" }}
              >
                <div className="flex items-center md:pr-1 mb-2 sm:mb-0 sm:pr-4 posicion-icono-caja">
                  <svg
                    class=" guia-icono-interno-guia"
                    width="24"
                    style={{ marginRight: "10px" }}
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2495 11.3901H15.288C15.5708 11.3901 15.7976 11.1697 15.7976 10.8949C15.7976 10.62 15.5708 10.3996 15.288 10.3996H14.2688V9.9044C14.2688 9.62955 14.042 9.40918 13.7591 9.40918C13.4763 9.40918 13.2495 9.62955 13.2495 9.9044V10.3996C12.4061 10.3996 11.7207 11.0657 11.7207 11.8853C11.7207 12.7049 12.4061 13.371 13.2495 13.371H14.2688C14.549 13.371 14.7784 13.5938 14.7784 13.8662C14.7784 14.1386 14.549 14.3614 14.2688 14.3614H12.2303C11.9475 14.3614 11.7207 14.5818 11.7207 14.8566C11.7207 15.1315 11.9475 15.3519 12.2303 15.3519H13.2495V15.8471C13.2495 16.1219 13.4763 16.3423 13.7591 16.3423C14.042 16.3423 14.2688 16.1219 14.2688 15.8471V15.3519C15.1122 15.3519 15.7976 14.6858 15.7976 13.8662C15.7976 13.0466 15.1122 12.3805 14.2688 12.3805H13.2495C12.9692 12.3805 12.7399 12.1577 12.7399 11.8853C12.7399 11.6129 12.9692 11.3901 13.2495 11.3901Z"
                      fill="#383838"
                    />
                    <path
                      d="M6.62484 3.96115H14.7786C15.0614 3.96115 15.2882 3.74078 15.2882 3.46593C15.2882 3.19108 15.0614 2.9707 14.7786 2.9707H6.62484C6.34201 2.9707 6.11523 3.19108 6.11523 3.46593C6.11523 3.74078 6.34201 3.96115 6.62484 3.96115Z"
                      fill="#383838"
                    />
                    <path
                      d="M6.62484 6.43771H14.7786C15.0614 6.43771 15.2882 6.21734 15.2882 5.94249C15.2882 5.66764 15.0614 5.44727 14.7786 5.44727H6.62484C6.34201 5.44727 6.11523 5.66764 6.11523 5.94249C6.11523 6.21734 6.34201 6.43771 6.62484 6.43771Z"
                      fill="#383838"
                    />
                    <path
                      d="M11.2113 7.92358H6.62484C6.34201 7.92358 6.11523 8.14396 6.11523 8.41881C6.11523 8.69366 6.34201 8.91403 6.62484 8.91403H11.2113C11.4942 8.91403 11.7209 8.69366 11.7209 8.41881C11.7209 8.14396 11.4942 7.92358 11.2113 7.92358Z"
                      fill="#383838"
                    />
                    <path
                      d="M9.68251 10.3997H6.62484C6.34201 10.3997 6.11523 10.62 6.11523 10.8949C6.11523 11.1697 6.34201 11.3901 6.62484 11.3901H9.68251C9.96534 11.3901 10.1921 11.1697 10.1921 10.8949C10.1921 10.62 9.96534 10.3997 9.68251 10.3997Z"
                      fill="#383838"
                    />
                    <path
                      d="M16.3075 0H2.03844C0.91475 0 0 0.888927 0 1.9809V13.371C0 13.6459 0.226777 13.8663 0.50961 13.8663H3.05766V19.3137C3.05766 19.4772 3.14175 19.6307 3.28189 19.7248C3.42203 19.8164 3.6004 19.8362 3.75583 19.7743L6.11532 18.8581L8.47482 19.7743C8.61241 19.8263 8.76275 19.8189 8.8927 19.757L10.7044 18.8779L12.516 19.757C12.646 19.8189 12.7963 19.8263 12.9339 19.7743L15.2934 18.8581L17.6529 19.7743C17.7141 19.7991 17.7778 19.809 17.8415 19.809C17.9408 19.809 18.0427 19.7792 18.1268 19.7248C18.267 19.6332 18.3511 19.4796 18.3511 19.3137V1.9809C18.3511 0.888927 17.4363 0 16.3126 0H16.3075ZM1.01922 12.8758V1.9809C1.01922 1.43367 1.47532 0.990448 2.03844 0.990448C2.60156 0.990448 3.05766 1.43367 3.05766 1.9809V12.8758H1.01922ZM17.3267 18.5833L15.4769 17.8652C15.3546 17.8182 15.2195 17.8182 15.0997 17.8652L12.7632 18.7739L10.9286 17.8825C10.7859 17.8132 10.6152 17.8132 10.4725 17.8825L8.63789 18.7739L6.30133 17.8652C6.17902 17.8182 6.04398 17.8182 5.92422 17.8652L4.07433 18.5833V1.9809C4.07433 1.61938 3.96732 1.28263 3.7915 0.990448H16.305C16.8681 0.990448 17.3242 1.43367 17.3242 1.9809V18.5833H17.3267Z"
                      fill="#383838"
                    />
                    <path
                      d="M9.68251 12.876H6.62484C6.34201 12.876 6.11523 13.0964 6.11523 13.3712C6.11523 13.646 6.34201 13.8664 6.62484 13.8664H9.68251C9.96534 13.8664 10.1921 13.646 10.1921 13.3712C10.1921 13.0964 9.96534 12.876 9.68251 12.876Z"
                      fill="#383838"
                    />
                  </svg>
                  <div
                    class="guia-tramites-mobil-posiciones-caja2"
                    style={{
                      " margin-top": "-30px !important",
                      "margin-left": "50px !important",
                    }}
                  >
                    <p className="font-semibold text-lg sm:text-xl titulo-guia-caja">
                      Costo del trámite:{" "}
                      <span className="text-lg sm:text-xl descripcion-guia-caja">
                        {procedure.info_additional.procedure_cost}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {procedure.info_additional.procedure_type && (
            <div class="h-auto border-b">
              <h2
                class=" font-semibold mb-2 text-lg titulo-guia-caja"
                style={{
                  "font-size": "20px",
                  "font-weight": "700",
                  color: "black",
                  "margin-top": "20px",
                  "margin-bottom": "10px",
                }}
              >
                Tipo de Trámite:{" "}
                <span class="font-semibold underline text-lg descripcion-guia-caja">
                  {procedure.info_additional.procedure_type}
                </span>
              </h2>
            </div>
          )}

          {procedure.info_additional.who_can_do_procedure && (
            <div>
              <h3
                class="text-lg font-semibold mb-1 titulo-guia-caja"
                style={{
                  "font-size": "20px",
                  "font-weight": "700",
                  color: "black",
                  "margin-top": "20px",
                  "margin-bottom": "10px",
                }}
              >
                Quién puede realizarlo:{" "}
              </h3>
              <p class="mb-4 text-lg descripcion-guia-caja">
                {procedure.info_additional.who_can_do_procedure}
              </p>
            </div>
          )}

          {procedure.info_additional.procedure_documentation && (
            <div>
              <h3
                class="text-lg font-semibold mb-1 titulo-guia-caja"
                style={{
                  "font-size": "20px",
                  "font-weight": "700",
                  color: "black",
                  "margin-top": "20px",
                  "margin-bottom": "10px",
                }}
              >
                Documentación a presentar:{" "}
              </h3>
              <p class="mb-8 text-lg descripcion-guia-caja">
                {procedure.info_additional.procedure_documentation}
              </p>
            </div>
          )}
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            {procedure.info_additional.subsidiaries.map((item, idx) => (
              <div key={idx} class=" border border-black">
                <div class="flex items-center mb-2 h-12 border-b tramites-ciudades-titulo">
                  <h4 class="text-lg font-semibold flex">
                    <svg
                      class="mr-2 ml-4"
                      width="19"
                      height="23"
                      viewBox="0 0 19 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.19968 0C4.12836 0 0 4.12672 0 9.19603C0 16.846 8.16472 22.9901 9.19968 22.9901C10.2346 22.9901 18.3994 16.846 18.3994 9.19603C18.3994 4.12672 14.271 0 9.19968 0ZM9.19968 21.8061C7.87723 21.1624 1.14996 15.7999 1.14996 9.19603C1.14996 4.75894 4.76084 1.1495 9.19968 1.1495C13.6385 1.1495 17.2494 4.75894 17.2494 9.19603C17.2494 15.8057 10.5221 21.1624 9.19968 21.8061Z"
                        fill="#383838"
                      />
                      <path
                        d="M9.19967 5.1731C6.98024 5.1731 5.1748 6.97782 5.1748 9.19636C5.1748 11.4149 6.98024 13.2196 9.19967 13.2196C11.4191 13.2196 13.2245 11.4149 13.2245 9.19636C13.2245 6.97782 11.4191 5.1731 9.19967 5.1731ZM9.19967 12.0701C7.61272 12.0701 6.32477 10.7827 6.32477 9.19636C6.32477 7.61004 7.61272 6.3226 9.19967 6.3226C10.7866 6.3226 12.0746 7.61004 12.0746 9.19636C12.0746 10.7827 10.7866 12.0701 9.19967 12.0701Z"
                        fill="#383838"
                      />
                    </svg>

                    {item.location}
                  </h4>
                </div>
                <div class="p-4">
                  <p class="font-semibold mb-2">{item.department}</p>
                  <p class="mb-2 font-semibold text-sm">
                    {" "}
                    <span class="block font-normal text-sm">
                      Descripción del Trámite:{" "}
                    </span>{" "}
                    {item.procedure_description}
                  </p>
                  <div class="grid grid-cols-2 gap-2">
                    <p class="mb-2 text-sm font-semibold">
                      <span class="block font-normal">• Dirección: </span>{" "}
                      {item.address}
                    </p>
                    <p class="mb-2 text-sm font-semibold">
                      <span class="block font-normal">
                        • Horario de atención:{" "}
                      </span>{" "}
                      {item.hours}
                    </p>
                    <p class="mb-2 text-sm font-semibold">
                      <span class="block font-normal">• Teléfonos: </span>{" "}
                      {item.telephone}
                    </p>
                  </div>
                </div>
                <div
                  class="h-12 bg-gray-100 flex items-center text-sm tramite-caja-mail"
                  style={{
                    "border-top": "#C7C7C7",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <p class="ml-6 ">
                    Mail:{" "}
                    <a class="font-semibold" href="mailto:{item.mail}">
                      {item.mail}
                    </a>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
};

const truncateText = (text, maxLength = 120) => {
  if (text.length <= maxLength) {
    return text;
  }

  const truncated = text.slice(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(" ");

  return lastSpaceIndex > 0
    ? truncated.slice(0, lastSpaceIndex) + "..."
    : truncated + "...";
};

export default Contenido;
