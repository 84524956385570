import React, { useEffect, useRef } from 'react';

import Malvina from './malvina.png';
import Accesibilidad from './Accesibilidad.png';
import AccesibilityWidget from './AccesibilityWidget.js';

const Widgets = () => {
    
    const botInitializedRef = useRef(false);

    useEffect(() => {
        if (window.location.pathname !== '/mapa-interactivo' && !window.location.pathname.startsWith('/chatbot') ) { // Verifica si no estás en /mapa-interactivo
            if (!botInitializedRef.current) {
                const existingScript = document.querySelector('script[src="https://go.botmaker.com/rest/webchat/p/2KDCSKC4KS/init.js"]');
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.src = 'https://go.botmaker.com/rest/webchat/p/2KDCSKC4KS/init.js';
                    script.onload = () => {
                        if (window.Botmaker && window.Botmaker.init) {
                            window.Botmaker.init(); 
                            botInitializedRef.current = true;
                        } else {
                            console.error('Botmaker script loaded, but init function not available.');
                        }
                    };
                    script.onerror = () => {
                        console.error('Failed to load Botmaker script.');
                    };
                    document.body.appendChild(script);
                } else {
                    console.log('Botmaker script already present in the document.');
                    if (window.Botmaker && window.Botmaker.init) {
                        window.Botmaker.init(); 
                        botInitializedRef.current = true;
                    }
                }
            }
        }
    }, [window.location.pathname]); // Se asegura de que se ejecute nuevamente si cambia la ruta

    return (
        <>
            <style>
                {`
                    .widgets-container {
                        position: fixed;
                        bottom: 0px; 
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: end;
                        width: 100%;
                    }
                    .widget-img {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                    }
                    @media screen and (max-width: 768px) {
                        .widgets-container {
                            display: none;
                        }
                    }
                    @media screen and (max-width: 500px) {
                        iframe[title*="Botmaker"] {
                            display: none;
                        }
                    }
                `}
            </style>
            {window.location.pathname !== '/mapa-interactivo' && (
                <div className="widgets-container w-full">
                    {/* <div className="">
                        <a style={{ width: '100px', height: '100px', borderRadius: '50%', cursor: 'pointer' }}>
                            <img src={Malvina} className="widget-img" alt="Malvina" />
                        </a>
                    </div> */}
                    <AccesibilityWidget />
                </div>
            )}
        </>
    );
};

export default Widgets;