import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config.json';
import useFetch from '../../hooks/useFetch';

const Servicios = () => {

    const [servicesContent, setServicesContent] = React.useState([]);

    const { data, error, isLoading, fetchData } = useFetch(
        'GET',
        `${config.API_URL}/maps/procedures.php?subprocedure=2&procedure_type=BC&action=json&source=Y2h1`,
        null,
        { 'Content-Type': 'application/json' });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {

        if (data) {
            setServicesContent(data.procedures || []);
        }

    }, [data]);
    const handleRedirect = (id) => {
        window.location.href = `/servicios?result_id=${id}`
    }

    if (servicesContent.length > 0) {

    return (
        <>
            <div className='container p-t-lg posicion-caja-servicios' style={{ padding: '0px', marginLeft:'25px' }} >
                <section className='w-full h-full  align-items-center'>
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-900 color-negro home-servicios-texto textos-separadores-cajas">Servicios</h1>
                </section>
            </div>

            <div className="">

                <div className="container-fluid p-t-lg menu-ancho-100" style={{ padding: '0px' }}>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {servicesContent.map((service, index) => (
                            <a onClick={() => handleRedirect(service.id)}  style={{ cursor: 'pointer' }}>
                            <div key={index} className="relative flex items-center w-full h-auto min-h-[163px] overflow-hidden p-4 bg-[#EFEFEF] rounded-xl caja-servicios">
                                <div className="flex p-4 rounded-lg justify-center items-center" style={{
                                    height: '129px',
                                    width: '108px',
                                    backgroundImage: `url(https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/iconosvg.png)`,
                                    backgroundSize: 'cover',
                                }}>
                                     <img className="w-50 h-50" src={service.icon} />
                                </div>
                                <div className="ml-4 flex-1 mt-2">
                                    <h2 className="text-lg md:text-xl font-bold text-gray-900 text-[17px] roboto" style={{ lineHeight: '22px', color:'#1C2026', fontWeight:'700' }}>
                                        {service.title}
                                    </h2>
                                    <p className="md:text-sm text-[17px] pt-[10px] text-gray-600 z-10 roboto" style={{ lineHeight: '18px', position: 'relative', marginRight: '40px',color:'#4b5563'}}>
                                        {service.subtitle}
                                    </p>
                                </div>
                                <div class="button-flecha z-0">
                                    <button className="absolute bottom-2 right-2 w-10 h-10 bg-orange-500 text-white p-2 rounded-full">
                                        {window.innerWidth < 768 ? (
                                            <svg className="ml-[6px]" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.629229 9.53157C0.277757 9.88304 0.277757 10.4529 0.629229 10.8044C0.980701 11.1558 1.55055 11.1558 1.90202 10.8044L0.629229 9.53157ZM11.2992 1.0344C11.2992 0.537342 10.8963 0.134398 10.3992 0.134398L2.29919 0.134399C1.80214 0.134398 1.3992 0.537342 1.3992 1.0344C1.3992 1.53145 1.80214 1.9344 2.29919 1.9344L9.4992 1.9344L9.4992 9.1344C9.4992 9.63145 9.90214 10.0344 10.3992 10.0344C10.8963 10.0344 11.2992 9.63145 11.2992 9.1344L11.2992 1.0344ZM1.90202 10.8044L11.0356 1.67079L9.7628 0.398002L0.629229 9.53157L1.90202 10.8044Z" fill="white" />
                                            </svg>
                                        ) : (
                                            <svg className="ml-[3px]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.00774 13.7343C0.617213 14.1248 0.617213 14.758 1.00774 15.1485C1.39826 15.539 2.03143 15.539 2.42195 15.1485L1.00774 13.7343ZM15.7148 1.44141C15.7148 0.889121 15.2671 0.441406 14.7148 0.441406L5.71484 0.441406C5.16256 0.441406 4.71484 0.889122 4.71484 1.44141C4.71484 1.99369 5.16256 2.44141 5.71484 2.44141L13.7148 2.44141L13.7148 10.4414C13.7148 10.9937 14.1626 11.4414 14.7148 11.4414C15.2671 11.4414 15.7148 10.9937 15.7148 10.4414L15.7148 1.44141ZM2.42195 15.1485L15.422 2.14851L14.0077 0.734299L1.00774 13.7343L2.42195 15.1485Z" fill="white" />
                                            </svg>
                                        )}
                                    </button>
                                </div>

                            </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            <style jsx>{`
          .button-flecha {
            background-image: url('https://chubut-img.s3.eu-west-2.amazonaws.com/Frontend/Subtract.png');
            width: 100px;
            height: 100px;
            float: right;
            position: absolute;
            bottom: -6px;
            right: -4px;
            background-position: 16px 22px;
            background-color: transparent;
            background-repeat: no-repeat;
          }
        `}</style>

        </>
    )} else {
        return null
    }
};

export default Servicios;