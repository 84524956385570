import { useState } from 'react';

const useFetch = (method, url, body, headers) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const requestOptions = {
        method,
        mode: 'cors'
      };

      if (method !== 'GET') {
        requestOptions.body = body ? JSON.stringify(body) : undefined;
      }

      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      if (!response.ok) {
        throw responseData; 
      }

      setData(responseData);
    } catch (errorResponse) { 
      setError(errorResponse);
    } finally {
      setIsLoading(false);
    }
  };

  console.log('useFetchData:', data, error);
  return { data, error, isLoading, fetchData };
};

export default useFetch;