// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/PassengerSans-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* src/styles/fonts.css */\n@font-face {\n    font-family: 'Passenger Sans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/css/fonts.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,6BAA6B;IAC7B,+DAAiE;IACjE,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["/* src/styles/fonts.css */\n@font-face {\n    font-family: 'Passenger Sans';\n    src: url('../fonts/PassengerSans-Regular.otf') format('opentype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
